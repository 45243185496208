import { isEmpty } from "lodash";
import servicesUtils from "../../../utils/servicesUtils";

const getCounts = (apiParams = {}) => {
    return new Promise((resolve, reject) => {
      servicesUtils
        .postService("/patients/counts/", apiParams)
        .then((data) => {
          resolve(data.data.count);
        })
        .catch((err) => {
          reject(err);
        });
    });
};

export default getCounts;
