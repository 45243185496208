import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { useStoreState } from "pullstate";

import useWorkspacesData from "../WorkspaceManagement/services/useWorkspaceData";
import Store from "../../utils/Store";
import DateRangeInput from "../FormComponents/DateRangeInput";
import Tag from "../SearchFilters/Tag";
import Toast from "../Toast";
import DropdownMenu from "../FormComponents/DropdownMenu";
import SelectInput from "../FormComponents/SelectInput";

const usecaseOptions = [
  {
    label: "Tuberculosis",
    value: "tb",
  },
  {
    label: "Tuberculosis and Lung Cancer",
    value: "tb_lung_cancer",
  },
  {
    label: "Lung Cancer",
    value: "lung_cancer",
  },
  {
    label: "Stroke Care",
    value: "stroke",
  },
];

const Filters = ({ filters, setFilters, isVisible, isUsecaseDisabled }) => {
  const { t } = useTranslation();

  const { userInfo } = useStoreState(Store, (s) => ({
    userInfo: s.userInfo,
  }));

  const sitesOptions = useMemo(() => {
    let sources = get(userInfo, ["read_sources"], []);
    return sources.reduce((acc, s) => {
      acc[s.uuid] = s.name;
      return acc;
    }, {});
  }, [userInfo]);

  const { data: workspaceData } = useWorkspacesData(isVisible);
  const ownedWorkspaces = get(workspaceData, ["owned_workspaces"], []);
  const workspaceOptions = ownedWorkspaces.map((w) => ({
    id: w.uuid,
    label: w.name,
    value: w.uuid,
  }));

  const handleDateRangeChange = (value) => {
    if (value[0] > new Date() || value[1] > new Date()) {
      Toast.error(t("toast.invalid_date"));
      return;
    }
    setFilters({
      ...filters,
      dateRange: value,
    });
  };

  const setDate = (daysFrom, daysTo) => {
    const d = new Date();
    const e = new Date();
    d.setDate(d.getDate() + daysFrom);
    e.setDate(e.getDate() + daysTo);
    setFilters({
      ...filters,
      dateRange: [d, e],
    });
  };

  return (
    <div className="space-y-6">
      {/*<div className="space-y-3">
        <div className="bg-gray-100 py-1 text-[12px] font-semibold dark:bg-transparent">
          <p>SITES</p>
        </div>
        <SelectInput
          options={workspaceOptions}
          value={filters.site}
          onChange={(val) => {
            setFilters({
              ...filters,
              site: val,
            });
          }}
          //disabled={selectedWorkspaceId && workspaceOptions?.length <= 1}
          className="w-full my-2"
        />
        </div>*/}
      <div className="space-y-3">
        <div className="text-[12px] font-semibold">USECASE</div>
        <SelectInput
          value={filters.usecase}
          options={usecaseOptions}
          disabled={isUsecaseDisabled}
          placeholder={"Select usecase"}
          onChange={(val) =>
            setFilters({
              ...filters,
              usecase: val,
            })
          }
          className="w-full my-2"
        />
      </div>
      <div className="space-y-3">
        <div className="text-[12px] font-semibold">DATE</div>
        <DateRangeInput
          tooltip={"Scan upload date"}
          placeholder={"Start Date - End Date"}
          value={filters.dateRange}
          onChange={handleDateRangeChange}
        />
        <div className="flex flex-wrap gap-3">
          <Tag label={"Last 1 week"} onClick={() => setDate(-7, 0)} labelMappings={{}} />
          <Tag
            label={"Last 1 month"}
            onClick={() => setDate(-30, 0)}
            labelMappings={{}}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
