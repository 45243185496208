import { get } from "lodash";
import constants from "../../utils/constants";
import Checkbox from "../common/Checkbox";
import BloodPressure from "../FormComponents/BloodPressure";
import CheckboxList from "../FormComponents/CheckboxList";
import DateTimeInput from "../FormComponents/DateTimeInput";
import SelectInput from "../FormComponents/SelectInput";
import SingleSelectTab from "../FormComponents/SingleSelectTab";
import TextInput from "../FormComponents/TextInput";

const FormControl = ({ control }) => {
  const { type, title, properties } = control;
  let units = get(properties, ["units", "one_of"], []);
  let choices = get(properties, ["choices"], []);
  let layout = get(properties, ["layout"], "");
  const options =
    choices &&
    choices.map((choice) => ({
      value: choice.id,
      label: choice.label,
      child: choice.children,
    }));

  switch (type) {
    case constants.FORM_FIELDS.DUAL_NUMBER:
      return <BloodPressure title={title} unit={units.length === 1 ? units[0] : units} />;
    case constants.FORM_FIELDS.NUMBER:
      return (
        <TextInput
          label={title}
          value={""}
          onChange={(val) => {}}
          isClinicalField
          unit={units.length === 1 ? units[0] : units}
          className="w-20"
          wrapperClassName="py-0"
          disabled={false}
        />
      );
    case constants.FORM_FIELDS.MULTISELECT:
      if (layout === "default") {
        return (
          <CheckboxList
            label={title}
            value={[]}
            options={options}
            onChange={(val) => {}}
          />
        );
      } else if (layout === "dropdown") {
        return (
          <SelectInput
            value={[]}
            multiple
            isClinicalField
            options={options}
            className="w-full"
            onChange={(val) => {}}
          />
        );
      }
    case constants.FORM_FIELDS.CHECKBOX:
      return (
        <Checkbox checked={false} label={title} onChange={() => {}} className="py-3" />
      );
    case constants.FORM_FIELDS.SINGLESELECT:
      return (
        <SingleSelectTab
          title={title}
          options={options}
          selected={"ID_LEFT"}
          onChange={() => {}}
        />
      );
    case constants.FORM_FIELDS.DATETIME:
    case constants.FORM_FIELDS.LKWT:
      return (
        <DateTimeInput
          label={title}
          value={""}
          isClinicalField
          onChange={(val) => {}}
          dateFormat="dd/MM/yyyy"
          datePlaceholder="DD/MM/YYYY"
          timeFormat="hh:mm aa"
          timePlaceholder="HH:MM aa"
          showTimeFirst
          disabled={false}
          required={false}
        />
      );
    default:
      return null;
  }
};

export default FormControl;
