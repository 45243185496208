import { DateRangePicker } from "@mantine/dates";
import useMedia, { MediaSizes } from "../../hooks/useMedia";
import { useStoreState } from "pullstate";
import Store from "../../utils/Store";

const DateRangeInput = ({ placeholder, value, onChange, tooltip }) => {
  const isMobile = useMedia([MediaSizes.MOBILE], [true], false);
  const { isDark } = useStoreState(Store, (s) => ({
    isDark: s.isDarkMode,
  }));

  value = value?.map((item) => {
    if (typeof item === "string") {
      return new Date(item);
    }
    return item;
  });

  const style = isDark
    ? {
        input: {
          fontSize: "12px",
          color: "#F6F7F7",
          border: "1px solid #646B72", // bg-gray-600
          borderRadius: "6px",
          backgroundColor: "#29333E", // bg-gray-900
          "&:focus-within": {
            borderColor: "#646B72", // bg-gray-600
          },
          "::placeholder": {
            color: "#F6F7F7",
          },
        },
      }
    : {
        input: {
          fontSize: "12px",
          border: "1px solid #646B72", // bg-gray-600
          borderRadius: "6px",
          backgroundColor: "#F6F7F7", // bg-gray-100
          // backgroundColor: "#0f172a", // bg-gray-900
          "&:focus-within": {
            borderColor: "#646B72", // bg-gray-600
          },
          "::placeholder": {
            color: "#646B72",
          },
        },
      };

  return (
    <div
      title={tooltip}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DateRangePicker
        allowSingleDateInRange={true}
        icon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3571 12C11.7107 12 12 12.3094 12 12.6875V16.8125C12 17.1906 11.7107 17.5 11.3571 17.5H7.5C7.14643 17.5 6.85714 17.1906 6.85714 16.8125V12.6875C6.85714 12.3094 7.14643 12 7.5 12H11.3571ZM8.14286 1C8.67723 1 9.10714 1.46191 9.10714 2.03125V3.75H14.8929V2.03125C14.8929 1.46191 15.3228 1 15.8571 1C16.3915 1 16.8214 1.46191 16.8214 2.03125V3.75H18.4286C19.8469 3.75 21 4.98105 21 6.5V20.25C21 21.7668 19.8469 23 18.4286 23H5.57143C4.15112 23 3 21.7668 3 20.25V6.5C3 4.98105 4.15112 3.75 5.57143 3.75H7.17857V2.03125C7.17857 1.46191 7.60848 1 8.14286 1ZM19.0714 9.25H4.92857V20.25C4.92857 20.6281 5.21625 20.9375 5.57143 20.9375H18.4286C18.7821 20.9375 19.0714 20.6281 19.0714 20.25V9.25Z"
              fill={isDark ? "#F6F7F7" : "#474F58"}
            />
          </svg>
        }
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        styles={{
          ...{
            root: {
              position: "relative",
            },
            wrapper: {},
            icon: {},
            dropdown: {
              backgroundColor: "#F6F7F7", // bg-gray-100
              border: "1px solid #646B72", // bg-gray-600
              transition: "none !important",
              marginTop: "-5px",
              display: "flex",
              justifyContent: "center",
              position: "fixed",
            },
            calendarHeaderControl: {
              "&:hover": {
                backgroundColor: "inherit",
              },
            },
            calendarHeaderLevel: {
              "&:hover": {
                backgroundColor: "inherit",
              },
            },
            monthPickerControlActive: {
              backgroundColor: "#00A9A7",
              "&:hover": {
                backgroundColor: "#00A9A7",
              },
            },
            yearPickerControlActive: {
              backgroundColor: "#00A9A7",
              "&:hover": {
                backgroundColor: "#00A9A7",
              },
            },
            month: {},
            day: {
              "&[data-selected]": {
                backgroundColor: "#00A9A7",
              },
              "&[data-in-range]": {
                backgroundColor: "#D9DBDD",
              },
              "&[data-first-in-range]": {
                backgroundColor: "#00A9A7",
              },
              "&[data-last-in-range]": {
                backgroundColor: "#00A9A7",
              },
            },
            cell: {},
            ...style,
          },
        }}
      />
    </div>
  );
};

export default DateRangeInput;
