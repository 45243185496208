import { getMWLURL, getMetabaseURL } from ".";
import { getBackendURL } from "./axiosConnect";

export const replaceHostName = (url, type) => {
  let authorityRegex = /^https?:\/\/[a-z0-9A-Z.\-:]+/;

  const backendUrl = getBackendURL();
  try {
    if (type == 'viewer') {
      authorityRegex = /^dicomweb:https?:\/\/[a-z0-9A-Z.\-:]+/;
      return url.replace(authorityRegex, `dicomweb:${backendUrl}`);
    }
    if (type == 'metabase') {
      const metabaseUrl = getMetabaseURL();
      authorityRegex = /^http?:\/\/[a-z0-9A-Z.\-:]+/;
      return url.replace(authorityRegex, metabaseUrl);
    }
    if (type == 'mwl') {
      const metabaseUrl = getMWLURL();
      authorityRegex = /^http?:\/\/[a-z0-9A-Z.\-:]+/;
      return url.replace(authorityRegex, metabaseUrl);
    }
    return url.replace(authorityRegex, backendUrl);
  } catch (e) {
    return "";
  }
};
