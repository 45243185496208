import { useMutation } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";
import { getIsOnpremise } from "../../../utils";

const useLogoutService = () => {
  const isOnPremise = getIsOnpremise()
  return useMutation(() =>
    servicesUtils.getService(
      isOnPremise
      ? "/accounts/local/logout/"
      : "/accounts/logout/", {
      next: window.location.href,
    })
  );
};

export default useLogoutService;
