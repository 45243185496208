import { useState, useEffect } from "react";
import { ExclamationTriangleIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

const NetworkError = () => {
  const [isOnline, setIsOnline] = useState(true);
  const [showNetworkError, setShowNetworkError] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    updateOnlineStatus();

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  const updateOnlineStatus = () => {
    if (navigator?.onLine) {
      setIsOnline(true);
    } else {
      setIsOnline(false);
    }
  };

  if (isOnline) {
    return null;
  }

  return (
    <div className="absolute bottom-0 w-full z-50">
      {showNetworkError && (
        <div className="text-center bg-gradient-to-b from-transparent to-gray-900 backdrop-blur-sm pt-6 pb-4">
          <div className="text-gray-100 flex items-center justify-center">
            <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500 mr-2" />
            {t("network_connection_interrupted")}
          </div>
          <button
            className="p-2 mt-3 rounded-full bg-gray-100"
            onClick={() => setShowNetworkError(false)}
          >
            <ChevronDownIcon className="h-6 w-6 text-gray-700" />
          </button>
        </div>
      )}
      <div className="relative w-full h-2 bg-yellow-400">
        <div className="w-[30%] absolute h-2 bg-gradient-to-r from-yellow-400 to-gray-800 animate-moveBar"></div>
      </div>
    </div>
  );
};

export default NetworkError;
