import { useMutation, useQuery } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";
export var useGetGateways = function useGetGateways(isOpen) {
  return useQuery(["gateways", isOpen], function () {
    return servicesUtils.getService("/profile/gateways/");
  }, {
    enabled: isOpen
  });
};
export var useGetPOCNumber = function useGetPOCNumber(isOpen) {
  return useQuery(["poc_phone", isOpen], function () {
    return servicesUtils.getService("/profile/poc_phone/");
  }, {
    enabled: isOpen
  });
};
export var useAddQurePOCNumber = function useAddQurePOCNumber() {
  return useMutation(function (params) {
    return servicesUtils.postService("/profile/poc_phone/", params);
  });
};
export var useAddITPOCNumber = function useAddITPOCNumber() {
  return useMutation(function (params) {
    return servicesUtils.postService("/gateway/".concat(params === null || params === void 0 ? void 0 : params.gateway_id, "/poc_phone/"), {
      phone: params === null || params === void 0 ? void 0 : params.phone
    });
  });
};