import { useStoreState } from "pullstate";
import Store from "../../utils/Store";
import useMedia, { MediaSizes } from "../../hooks/useMedia";
import { useRouter } from "next/router";
import { useEffect } from "react";
var useSettings = function useSettings() {
  var isMobile = useMedia([MediaSizes.MOBILE], [true], false);
  var router = useRouter();
  var _useStoreState = useStoreState(Store, function (s) {
      return {
        isSettingsOpen: s.isSettingsOpen,
        hasUnsavedSetting: s.hasUnsavedSetting,
        selectedSetting: s.selectedSetting,
        settingPageNo: s.settingPageNo
      };
    }),
    isSettingsOpen = _useStoreState.isSettingsOpen,
    selectedSetting = _useStoreState.selectedSetting,
    hasUnsavedSetting = _useStoreState.hasUnsavedSetting;
  useEffect(function () {
    if (!isSettingsOpen) {
      Store.update(function (s) {
        s.settingParams = {};
      });
    }
  }, [isSettingsOpen]);
  var openSetting = function openSetting(pageName) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var _isSettingsOpen = isSettingsOpen;
    var _selectedSetting = selectedSetting;
    if (isMobile) {
      router.push("/settings/".concat(pageName));
    } else {
      _isSettingsOpen = true;
      _selectedSetting = pageName;
    }
    Store.update(function (s) {
      s.isSettingsOpen = _isSettingsOpen;
      s.selectedSetting = _selectedSetting;
      s.settingParams = params;
    });
  };
  return {
    isSettingsOpen: isSettingsOpen,
    hasUnsavedSetting: hasUnsavedSetting,
    openSetting: openSetting
  };
};
export default useSettings;