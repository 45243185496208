import { useRouter } from "next/router";
import constants from "../utils/constants";
function useCheckWorklistContainer() {
  var _useRouter = useRouter(),
    pathname = _useRouter.pathname;
  if (pathname.includes(constants.SCREENS.WORKLIST) || pathname === "/") {
    return true;
  }
  return false;
}
export default useCheckWorklistContainer;