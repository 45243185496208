import { getIsOnpremise } from "../../../utils";
import { replaceHostName } from "../../../utils/replaceHostName";
import servicesUtils from "../../../utils/servicesUtils";
import Store from "../../../utils/Store";

function patientsExportService(url, params) {
  return servicesUtils.postService(url, params);
}

const downloadFile = () => {
  const isOnpremise = getIsOnpremise();
  const url = "patients_export/get_download_link/";
  patientsExportService(url)
    .then((response) => {
      const link = document.createElement("a");
      let csv_link = response.data?.csv_link;
      if (isOnpremise) {
        csv_link = replaceHostName(csv_link);
      }
      link.href = csv_link;
      link.setAttribute("download", "report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      Store.update((s) => {
        s.showExportUpdate = false;
      });
    })
    .catch((resp) => {
      Store.update((s) => {
        s.showExportUpdate = true;
        s.exportGenFailed = true;
      });
    });
};

export { downloadFile, patientsExportService };
