import { useState, useEffect } from "react";
var desktop = 960;
var tablet = 768;
var MediaSizes = {
  MOBILE: "(max-width: 639px)",
  TABLET: "(max-width: 767px)",
  DESKTOP: "(max-width: 1023px)",
  LARGE_DESKTOPS: "(min-width: 1023px)"
};

// Bulma breakpoints reference

// // The container horizontal gap, which acts as the offset for breakpoints
// $gap: 32px !default
// // 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
// $tablet: 769px !default
// // 960px container + 4rem
// $desktop: 960px + (2 * $gap) !default
// // 1152px container + 4rem
// $widescreen: 1152px + (2 * $gap) !default
// $widescreen-enabled: true !default
// // 1344px container + 4rem
// $fullhd: 1344px + (2 * $gap) !default
// $fullhd-enabled: true !default

// Hook
function useMedia(queries, values, defaultValue) {
  // Array containing a media query list for each query
  var mediaQueryLists = queries.map(function (q) {
    return window.matchMedia(q);
  });

  // Function that gets value based on matching media query
  var getValue = function getValue() {
    // Get index of first media query that matches
    var index = mediaQueryLists.findIndex(function (mql) {
      return mql.matches;
    });
    // Return related value or defaultValue if none
    return typeof values[index] !== "undefined" ? values[index] : defaultValue;
  };

  // State and setter for matched value
  var _useState = useState(getValue),
    value = _useState[0],
    setValue = _useState[1];
  useEffect(function () {
    // Event listener callback
    // Note: By defining getValue outside of useEffect we ensure that it has ...
    // ... current values of hook args (as this hook callback is created once on mount).
    var handler = function handler() {
      return setValue(getValue);
    };
    // Set a listener for each media query with above handler as callback.
    mediaQueryLists.forEach(function (mql) {
      return mql.addListener(handler);
    });
    // Remove listeners on cleanup
    return function () {
      return mediaQueryLists.forEach(function (mql) {
        return mql.removeListener(handler);
      });
    };
  }, [] // Empty array ensures effect is only run on mount and unmount
  );
  return value;
}
export { useMedia as default, MediaSizes };