import classNames from "classnames";
import useMedia, { MediaSizes } from "../../hooks/useMedia";
import constants from "../../utils/constants";

const Tag = ({ label, onClick, isSelected, labelMappings, disabled }) => {
  const isMobile = useMedia([MediaSizes.MOBILE], [true], false);

  return (
    <button
      disabled={disabled}
      className={classNames(
        "rounded-full border border-gray-600 w-max px-5 cursor-pointer sm:text-[12px] sm:py-2",
        {
          "!bg-teal-400 !text-gray-100 !border-teal-400": isSelected,
          "hover:border-teal-400 hover:bg-teal-400 hover:text-gray-100":
            !isMobile && !disabled,
          "opacity-50 !cursor-not-allowed": disabled,
        }
      )}
      onClick={onClick}
    >
      {labelMappings[label] || label}
    </button>
  );
};

export default Tag;
