import servicesUtils from "../../../utils/servicesUtils";

const getSyncStatus = (apiParams = {}) => {
  return new Promise((resolve, reject) => {
    servicesUtils
      .getService("/qsync/status/", apiParams)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getSyncStatus;
