import { useMutation } from "react-query";
import servicesUtils from "../../utils/servicesUtils";
function useAgreementService() {
  var url = "/agreement/accept/";
  return useMutation(function (version) {
    return servicesUtils.postService(url, {
      version: version
    });
  });
}
export default useAgreementService;