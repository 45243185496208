import { useStoreState } from "pullstate";
import redirectToLogin from "../../utils/redirectToLogin";
import Store from "../../utils/Store";
import Button from "../common/Button";
import Modal from "../common/Modal";
import Toast from "../Toast";
import useLogoutService from "./services/logoutService";
import { useTranslation } from "react-i18next";
import constants from "../../utils/constants";
import useUnregisterNotification from "./services/unregisterService";
import { isIOSWebView } from "../../utils";
import { trackEvent } from "../../utils/mixpanel";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const Logout = () => {
  const { t } = useTranslation();
  const { isVisible } = useStoreState(Store, (s) => ({ isVisible: s.isLogoutOpen }));
  const logoutService = useLogoutService();
  const unregisterNotification = useUnregisterNotification();
  const { fcmToken, RNfcmToken } = useStoreState(Store, (s) => ({
    RNfcmToken: s.RNfcmToken,
    fcmToken: s.fcmToken,
  }));

  const onCloseLogout = () => {
    Store.update((s) => {
      s.isLogoutOpen = false;
    });
  };

  const onLogout = async () => {
    try {
      const old_fcmToken = window?.localStorage.getItem(constants.PERSISTED.fcmToken);
      if (RNfcmToken || fcmToken || old_fcmToken) {
        await unregisterNotification.mutateAsync(RNfcmToken || fcmToken || old_fcmToken);
      }
      const response = await logoutService.mutateAsync();
      // desktop app integration
      if (window.electronAPI) {
        window.electronAPI.setAPISettings({
          apiURL: null,
          token: null,
        });
      }
      // delete shadow cookie created for iOS webview
      if (isIOSWebView()) {
        let now = new Date();
        let year = now.getFullYear();
        now.setFullYear(year - 1);
        document.cookie = `sessionid=;Domain=.qure.ai;Expires=${now.toUTCString()};Path=/;Secure;`;
      }

      // localStorage cleanup
      localStorage?.removeItem(constants.PERSISTED.fcmToken);
      localStorage?.removeItem(constants.PERSISTED.filterObj);
      localStorage?.removeItem(constants.PERSISTED.savedFilterData);
      localStorage?.removeItem(constants.PERSISTED.isFullWidthWorklist);
      localStorage?.removeItem(constants.PERSISTED.isSwiftReportModeEnabled);
      localStorage?.removeItem(constants.PERSISTED.notificationPromptDismiss);

      window.location.replace(response?.logout_url);

      trackEvent(MIXPANEL_EVENTS.USER_LOGGEDOUT);
      // reset mixpanel identity
      if (!!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
        mixpanel.reset();
      }
    } catch (e) {
      Toast.error(t("toast.something_went_wrong"));
      redirectToLogin();
    }
  };

  return (
    <Modal
      show={isVisible}
      onClose={onCloseLogout}
      title="Confirm Logout?"
      className="w-full sm:w-5/12 lg:w-4/12"
      closeOnClickAway
    >
      <div className="p-6">Are you sure you want to logout?</div>
      <div className="flex items-center justify-end sm:justify-end p-4 w-full">
        <div className="flex items-center justify-center w-full sm:w-3/4 gap-4">
          <Button isSecondary className="grow w-full sm:w-auto" onClick={onCloseLogout}>
            Cancel
          </Button>
          <Button
            isPrimary
            isLoading={logoutService.isLoading || unregisterNotification.isLoading}
            className="mt-0 grow w-full sm:w-auto"
            onClick={onLogout}
          >
            Logout
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Logout;
