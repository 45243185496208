import { useStoreState } from "pullstate";
import Store from "../../utils/Store";
import { downloadFile, patientsExportService } from "./services/downloadService";
import { trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const ExportMessage = () => {
  const {
    isExportReady,
    exportTimestamp,
    downloadProgress,
    exportGenFailed,
    showExportUpdate,
  } = useStoreState(Store, (store) => ({
    showExportUpdate: store.showExportUpdate,
    isExportReady: store.isExportReady,
    downloadProgress: store.downloadProgress,
    exportTimestamp: store.exportTimestamp,
    exportGenFailed: store.exportGenFailed,
  }));

  const onClose = () => {
    Store.update((s) => {
      s.showExportUpdate = false;
    });
  };

  if (!showExportUpdate) {
    return null;
  } else {
    setTimeout(() => {
      Store.update((s) => {
        s.showExportUpdate = false;
      });
    }, 10000);
  }

  return (
    <div
      aria-hidden="true"
      className="absolute bottom-2 left-2 flex w-80 bg-white dark:bg-gray-800 text-black dark:text-white p-3 pl-4 rounded-md cursor-pointer z-50 border border-gray-400"
    >
      {downloadProgress > 0 && downloadProgress < 100 && !isExportReady && (
        <ExportUpdate
          message="Export data file is being downloaded to your system."
          type="update"
        />
      )}

      {exportGenFailed && (
        <ExportUpdate
          message="Export file could not be prepared. Please export file again."
          type="error"
        />
      )}

      {isExportReady && <DownloadExport timestamp={exportTimestamp} />}

      <div className="py-2" onClick={onClose}>
        <svg
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.705 10.4688C12.0563 10.8193 12.0563 11.3879 11.705 11.7384C11.3536 12.0889 10.7835 12.0888 10.4321 11.7384L6.00061 7.28553L1.53765 11.7369C1.18628 12.0873 0.616179 12.0873 0.264768 11.7369C-0.0866433 11.3864 -0.0866058 10.8178 0.264768 10.4673L4.72923 6.01745L0.26353 1.53243C-0.0878435 1.18197 -0.0878435 0.613357 0.26353 0.262859C0.614904 -0.0876384 1.185 -0.087601 1.53641 0.262859L6.00061 4.74938L10.4636 0.298021C10.8149 -0.0524392 11.385 -0.0524392 11.7365 0.298021C12.0879 0.648481 12.0878 1.2171 11.7365 1.56759L7.27199 6.01745L11.705 10.4688Z"
            fill="#D9DBDD"
          />
        </svg>
      </div>
    </div>
  );
};

const ExportUpdate = ({ message, type }) => {
  return (
    <div className="px-1 flex">
      <div className="py-1">
        {type == "update" ? (
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="#00D0CD"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17.0391 10.6875H15.0537L13.2725 12.375H17.0391C17.3664 12.375 17.6328 12.6274 17.6328 12.9375V15.75C17.6328 16.0601 17.3664 16.3125 17.0391 16.3125H2.78906C2.46168 16.3125 2.19531 16.0601 2.19531 15.75V12.9375C2.19531 12.6281 2.46176 12.375 2.78906 12.375H6.55566L4.77441 10.6875H2.78906C1.47725 10.6875 0.414062 11.6947 0.414062 12.9375V15.75C0.414062 16.9928 1.47725 18 2.78906 18H17.0391C18.3509 18 19.4141 16.9928 19.4141 15.75V12.9375C19.4141 11.6965 18.349 10.6875 17.0391 10.6875ZM16.4453 14.3438C16.4453 13.8776 16.0464 13.5 15.5547 13.5C15.063 13.5 14.6641 13.8762 14.6641 14.3438C14.6641 14.8096 15.063 15.1875 15.5547 15.1875C16.0464 15.1875 16.4453 14.8113 16.4453 14.3438ZM9.2832 12.941C9.45762 13.1379 9.68398 13.2188 9.91406 13.2188C10.1441 13.2188 10.3698 13.1363 10.5438 12.9716L15.5907 8.19032C15.9386 7.86073 15.9386 7.32688 15.5907 6.99711C15.2428 6.66735 14.6793 6.66752 14.3312 6.99711L10.8047 10.3395V0.84375C10.8047 0.37793 10.4076 0 9.91406 0C9.42051 0 9.02344 0.37793 9.02344 0.84375V10.3395L5.46465 6.99609C5.11675 6.6665 4.55324 6.6665 4.20516 6.99609C3.85707 7.32568 3.85726 7.85953 4.20516 8.1893L9.2832 12.941Z" />
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0004 14.4654C11.3105 14.4654 10.7504 15.0256 10.7504 15.7154C10.7504 16.4053 11.3105 16.9654 12.0004 16.9654C12.6902 16.9654 13.2504 16.4053 13.2504 15.7154C13.2504 15.0256 12.6918 14.4654 12.0004 14.4654ZM11.0629 7.90293V12.2779C11.0629 12.7955 11.4828 13.2154 12.0004 13.2154C12.518 13.2154 12.9379 12.7955 12.9379 12.2779V7.90293C12.9379 7.38535 12.518 6.96543 12.0004 6.96543C11.4828 6.96543 11.0629 7.41855 11.0629 7.90293ZM21.4418 10.6959L13.309 2.52793C12.5664 1.82402 11.3539 1.82402 10.6504 2.52793L2.55781 10.6959C1.81406 11.3982 1.81406 12.6111 2.55781 13.3135L10.6906 21.4424C11.3945 22.1861 12.6066 22.1861 13.3105 21.4424L21.4434 13.3135C22.1879 12.61 22.1879 11.399 21.4418 10.6959ZM12.0004 20.1021L3.90039 12.0045L11.9551 3.87559H11.9664C11.9826 3.87559 11.9976 3.87932 12.0077 3.88306C12.0126 3.88436 12.0151 3.88558 12.0177 3.8868L20.0997 12.004L12.0004 20.1021Z"
              fill="#E8414E"
            />
          </svg>
        )}
      </div>
      <div className="px-2">{message}</div>
    </div>
  );
};

const DownloadExport = ({ timestamp }) => {
  return (
    <div className="w-80">
      <div
        className="py-2 flex"
        onClick={() => {
          downloadFile();
          trackEvent(MIXPANEL_EVENTS.EXPORT_DATA_SETTINGS);
        }}
      >
        <div className="px-3">
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="#00D0CD"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M17.0391 10.6875H15.0537L13.2725 12.375H17.0391C17.3664 12.375 17.6328 12.6274 17.6328 12.9375V15.75C17.6328 16.0601 17.3664 16.3125 17.0391 16.3125H2.78906C2.46168 16.3125 2.19531 16.0601 2.19531 15.75V12.9375C2.19531 12.6281 2.46176 12.375 2.78906 12.375H6.55566L4.77441 10.6875H2.78906C1.47725 10.6875 0.414062 11.6947 0.414062 12.9375V15.75C0.414062 16.9928 1.47725 18 2.78906 18H17.0391C18.3509 18 19.4141 16.9928 19.4141 15.75V12.9375C19.4141 11.6965 18.349 10.6875 17.0391 10.6875ZM16.4453 14.3438C16.4453 13.8776 16.0464 13.5 15.5547 13.5C15.063 13.5 14.6641 13.8762 14.6641 14.3438C14.6641 14.8096 15.063 15.1875 15.5547 15.1875C16.0464 15.1875 16.4453 14.8113 16.4453 14.3438ZM9.2832 12.941C9.45762 13.1379 9.68398 13.2188 9.91406 13.2188C10.1441 13.2188 10.3698 13.1363 10.5438 12.9716L15.5907 8.19032C15.9386 7.86073 15.9386 7.32688 15.5907 6.99711C15.2428 6.66735 14.6793 6.66752 14.3312 6.99711L10.8047 10.3395V0.84375C10.8047 0.37793 10.4076 0 9.91406 0C9.42051 0 9.02344 0.37793 9.02344 0.84375V10.3395L5.46465 6.99609C5.11675 6.6665 4.55324 6.6665 4.20516 6.99609C3.85707 7.32568 3.85726 7.85953 4.20516 8.1893L9.2832 12.941Z" />
          </svg>
        </div>
        <div>
          <p className="text-teal-300">Download export data file</p>
          <p className="flex text-sm text-gray-600 dark:text-gray-400 font-normal">
            Generated on {timestamp}
          </p>
        </div>
      </div>
      <hr></hr>
      <div className="pt-4 flex" onClick={startDownload}>
        <div className="px-3 py-1">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#00D0CD"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19.0625 9.06255C18.5449 9.06255 18.125 9.48247 18.125 10C18.125 12.2399 16.3023 14.0625 14.0625 14.0625H5.69922L8.16133 11.6004C8.52754 11.2342 8.52754 10.6411 8.16133 10.2747C7.79512 9.90825 7.20195 9.90844 6.83555 10.2747L2.77305 14.3371C2.40684 14.7033 2.40684 15.2965 2.77305 15.6629L6.83555 19.7254C7.01953 19.9102 7.25781 20 7.5 20C7.74219 20 7.97969 19.9084 8.16289 19.7254C8.5291 19.3591 8.5291 18.766 8.16289 18.3996L5.69922 15.9375H14.0625C17.3363 15.9375 20 13.2739 20 10C20 9.48052 19.582 9.06255 19.0625 9.06255ZM5.9375 5.93756H14.3008L11.8387 8.39966C11.4725 8.76587 11.4725 9.35903 11.8387 9.72544C12.0195 9.9102 12.2578 10 12.5 10C12.7422 10 12.9797 9.90848 13.1629 9.7254L17.2254 5.66291C17.5916 5.2967 17.5916 4.70354 17.2254 4.33714L13.1629 0.274657C12.7967 -0.0915523 12.2035 -0.0915523 11.8371 0.274657C11.4707 0.640866 11.4709 1.23403 11.8371 1.60043L14.3008 4.06257H5.9375C2.66367 4.06257 0 6.72662 0 9.96489C0 10.5157 0.419922 10.9375 0.9375 10.9375C1.45508 10.9375 1.875 10.5196 1.875 10C1.875 7.76177 3.69766 5.93756 5.9375 5.93756Z" />
          </svg>
        </div>
        <div>Export new file</div>
      </div>
    </div>
  );
};

const startDownload = () => {
  Store.update((s) => {
    s.isExportDataOpen = true;
    s.showExportUpdate = false;
  });
  trackEvent(MIXPANEL_EVENTS.EXPORT_NEW_DATA_SETTINGS);
};

export default ExportMessage;
