import classNames from "classnames";

const RadioList = (props) => {
  const { label, name, options, value, onChange, className, required, disabled } = props;

  const onChangeValue = (newVal) => {
    onChange(newVal);
  };

  return (
    <div
      className={classNames(
        "flex flex-col w-full text-gray-900 dark:text-gray-100 text-left",
        { "opacity-50": disabled }
      )}
    >
      {!!label && (
        <div className={classNames("flex text-base pb-4")}>
          {label}
          {!!required && <span className="text-red-500 pl-2">*</span>}
        </div>
      )}
      <div
        className={classNames("flex flex-col space-y-4", {
          [className]: !!className,
        })}
      >
        {options.map((option) => {
          const { label, value: currentVal } = option;
          const id = name + currentVal;
          return (
            <div key={id} className="flex items-start">
              <input
                id={id}
                name={name}
                type="radio"
                checked={value === currentVal}
                disabled={disabled}
                className="h-5 w-5 border-gray-300 text-teal-500 cursor-pointer mt-1"
                onChange={() => onChangeValue(currentVal)}
              />
              <label
                htmlFor={id}
                className="ml-3 block font-medium text-gray-700 dark:text-gray-200 cursor-pointer"
              >
                {label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RadioList;
