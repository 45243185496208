import { useStoreState } from "pullstate";
import Modal from "../common/Modal";
import Store from "../../utils/Store";
import RegionDropdown from "./RegionDropdown";

const ChangeRegionDropdown = () => {

  const { isVisible } = useStoreState(Store, (s) => ({
    isVisible: s.isChangeRegionModalOpen,
  }));

  const onClose = () => {
    Store.update((s) => {
      s.isChangeRegionModalOpen = false;
    });
  };

  return (
    <Modal
      show={isVisible}
      onClose={onClose}
      title="Change Region"
      className="sm:!w-80 !bg-gray-100 dark:!bg-gray-800 sm:h-max"
      closeOnClickAway
    >
      
    </Modal>
  );
};

export default ChangeRegionDropdown;
