import { getIsOnpremise, getUrlAuthToken } from ".";
import LoggedInToken from "./LoggedInToken";
import constants from "./constants";
var isOnpremise = getIsOnpremise();
export var getLoginURL = function getLoginURL() {
  var _window;
  if (typeof window === "undefined") {
    return process.env.NEXT_PUBLIC_API_URL;
  }
  if (!isOnpremise) {
    return process.env.NEXT_PUBLIC_API_URL;
  }
  var hostname = (_window = window) === null || _window === void 0 ? void 0 : _window.location.hostname;
  return "http://".concat(hostname, ":").concat(process.env.NEXT_PUBLIC_ONPREMISE_FRONTEND_PORT);
};
var redirectToLogin = function redirectToLogin() {
  /* eslint-disable no-console */
  console.info("Redirecting to login page.");
  var urlAuthToken = getUrlAuthToken();
  if (urlAuthToken) {
    var _window2;
    (_window2 = window) === null || _window2 === void 0 || (_window2 = _window2.localStorage) === null || _window2 === void 0 || _window2.setItem(constants.PERSISTED.redirectAfterLogin, window.location.href);
  }
  var next = "".concat(window.location.origin, "/open-app/");
  var apiUrl = getLoginURL();
  var loginURL = "".concat(apiUrl, "/accounts/login/?next=").concat(next);
  LoggedInToken.setLogInPorgress();
  if (window.ReactNativeWebView) {
    var _window3;
    // react native app
    (_window3 = window) === null || _window3 === void 0 || (_window3 = _window3.ReactNativeWebView) === null || _window3 === void 0 || _window3.postMessage(JSON.stringify({
      event: constants.RN_COMM_CONST.LOGIN,
      data: loginURL
    }));
  } else if (window.electronAPI) {
    // electron app
    window.open(loginURL);
  } else {
    window.location.href = loginURL;
  }
};
export default redirectToLogin;