import mixpanel from "mixpanel-browser";
import { getAllSources, getIsOnpremise, getPlatform, isWebView } from ".";
import constants from "./constants";
import { isEmpty } from "lodash";
import { MIXPANEL_OTHER_EVENTS } from "./mixpanelEvents";
import Store from "./Store";
var PROD = "production";
var LOCALSTORAGE = "localStorage";
function getUserProperties(userData) {
  var sources = getAllSources(userData);
  var sourceIds = sources.map(function (source) {
    return source.uuid;
  });
  var PLATFORMS = constants.PLATFORMS;
  var platform = Object.keys(PLATFORMS).find(function (p) {
    return PLATFORMS[p] === getPlatform();
  });
  return {
    Name: userData.details.name,
    Email: userData.details.email,
    sourceIds: sourceIds,
    isMobielApp: isWebView(),
    platform: platform
  };
}
var isLoggedInUser = function isLoggedInUser() {
  var _Store$getRawState;
  return !isEmpty((_Store$getRawState = Store.getRawState()) === null || _Store$getRawState === void 0 ? void 0 : _Store$getRawState.user);
};
function initMixpanel() {
  var MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
  var isProduction = process.env.NEXT_PUBLIC_APP_ENV === PROD;
  var isOnPremise = getIsOnpremise();
  if (isOnPremise || !MIXPANEL_TOKEN) {
    return function () {};
  }
  mixpanel.init(MIXPANEL_TOKEN, {
    debug: !isProduction,
    track_pageview: false,
    persistence: LOCALSTORAGE
  });
  return function (userData) {
    mixpanel.identify(userData.username);
    var userProperties = getUserProperties(userData);
    mixpanel.people.set_once(userProperties);
  };
}
var trackPageView = function trackPageView(pageName) {
  var isOnPremise = getIsOnpremise();
  var MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
  if (isOnPremise || !MIXPANEL_TOKEN || !isLoggedInUser()) {
    return;
  }
  try {
    mixpanel.track_pageview({
      page: pageName
    });
    trackDuration(MIXPANEL_OTHER_EVENTS.PAGE_EXIT);
  } catch (e) {
    console.log("Mixpanel not initialized :", e);
  }
};
var trackEvent = function trackEvent(name, props) {
  var isOnPremise = getIsOnpremise();
  var MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
  if (isOnPremise || !MIXPANEL_TOKEN || !isLoggedInUser()) {
    return;
  }
  if (isEmpty(props)) {
    mixpanel.track(name);
    return;
  }
  try {
    mixpanel.track(name, props);
  } catch (e) {
    console.log("Mixpanel not initialized :", e);
  }
};
var trackPageExit = function trackPageExit(page) {
  var isOnPremise = getIsOnpremise();
  var MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
  if (isOnPremise || !MIXPANEL_TOKEN || !isLoggedInUser()) {
    return;
  }
  mixpanel.track(MIXPANEL_OTHER_EVENTS.PAGE_EXIT, {
    page: page
  });
};

/* this function will track duration will target event is hit */
var trackDuration = function trackDuration(targetEvent) {
  var isOnPremise = getIsOnpremise();
  var MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
  if (isOnPremise || !MIXPANEL_TOKEN || !isLoggedInUser()) {
    return;
  }
  mixpanel.time_event(targetEvent);
};
export { initMixpanel, trackPageView, trackEvent, trackPageExit, trackDuration };