import classNames from "classnames";

const SingleSelectTab = ({ options, selected, onChange, title, disabled }) => {
  return (
    <div className={classNames("flex flex-col items-start", { "opacity-60": disabled })}>
      <div className="flex mb-5 text-left">{title}</div>
      <div className="flex rounded-6  text-gray-100 divide-x divide-gray-200 dark:divide-teal-500 overflow-hidden">
        {options.map((option, index) => {
          const { value, label } = option;
          return (
            <div
              key={value}
              className={classNames(
                "flex items-center justify-center px-6 py-3 min-w-9 cursor-pointer bg-gray-600 dark:bg-gray-800 flex-1",
                {
                  "bg-teal-400 dark:bg-teal-500": selected === value,
                  "!cursor-default": disabled,
                }
              )}
              onClick={() => {
                if (!disabled) {
                  onChange(value);
                }
              }}
            >
              {label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SingleSelectTab;
