/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStoreState } from "pullstate";
import { get, set } from "lodash";
import { add } from "date-fns";

import Store from "../../utils/Store";
import Button from "../common/Button";
import Filters from "./Filters";
import { patientsExportService } from "../ExportMessage/services/downloadService";
import { formatFiltersObj } from "../SearchFilters/utils";
import { isValidDate } from "../../utils";

const initialFilters = {
  dateRange: [null, null],
  site: {
    tags: {
      dropdownTags: [],
      tags: [],
      dropdownMenuTags: [],
    },
  },
};

const ExportData = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const [filters, setFilters] = useState(initialFilters);

  // for enabling only tb in the filters usecase dropdown
  const isUsecaseDisabled = process.env.NEXT_PUBLIC_DISABLE_OTHER_USECASE === "true";
  const { downloadProgress, isStartingDownload } = useStoreState(Store, (store) => ({
    downloadProgress: store.downloadProgress,
    isStartingDownload: store.isStartingDownload,
  }));

  useEffect(() => {
    if (isUsecaseDisabled) {
      initialFilters.usecase = "tb";
    }
  }, [isUsecaseDisabled]);

  const getFormattedFilters = (filters) => {
    const { usecase } = filters;
    let formattedFilters = formatFiltersObj(filters);
    let portalusecases = undefined;
    switch (usecase) {
      case "tb":
        portalusecases = ["tb"];
        break;

      case "tb_lung_cancer":
        portalusecases = ["tb", "lung_cancer"];
        break;

      case "lung_cancer":
        portalusecases = ["lung_cancer"];
        break;

      case "stroke":
        portalusecases = ["stroke"];
        break;

      default:
        break;
    }
    let startDate = get(formattedFilters, ["start_study_date"], "");
    let endDate = get(formattedFilters, ["end_study_date"], "");

    if (startDate) {
      let startDateVal = new Date(startDate);
      let endDateVal = add(new Date(endDate), { days: 1 });
      if (isValidDate(startDateVal) && isValidDate(endDateVal)) {
        startDateVal.setHours(0, 0, 0);
        endDateVal.setHours(0, 0, 0);
        set(formattedFilters, ["start_study_date"], startDateVal.toISOString());
        set(formattedFilters, ["end_study_date"], endDateVal.toISOString());
      }
    }

    return {
      ...formattedFilters,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      usecases: portalusecases,
    };
  };

  const startDownload = () => {
    if ((downloadProgress === 0 || downloadProgress === 100) && !isStartingDownload) {
      Store.update((s) => {
        s.isExportReady = false;
        s.downloadProgress = 1;
        s.exportGenFailed = false;
        s.isStartingDownload = true;
      });
      const url = "patients_export/start_generation/";
      const formattedFilters = getFormattedFilters(filters);
      patientsExportService(url, formattedFilters)
        .then((response) => {
          Store.update((s) => {
            s.isStartingDownload = false;
          });
          if (response?.success) {
            Store.update((s) => {
              s.isExportReady = false;
              s.downloadProgress = 1;
              s.exportGenFailed = false;
            });
          }
        })
        .catch((resp) => {
          Store.update((s) => {
            s.isExportReady = false;
            s.downloadProgress = 0;
            s.exportGenFailed = true;
            s.isStartingDownload = false;
          });
        });
    }
    onClose();
  };

  return (
    <>
      <div className="m-4 flex flex-col text-left space-y-4 h-full">
        <Filters
          isVisible
          filters={filters}
          setFilters={setFilters}
          isUsecaseDisabled={isUsecaseDisabled}
        />
      </div>
      <div className="flex items-center flex-row justify-end p-4 border-t border-t-gray-600">
        <Button isSecondary className="grow-1 w-full sm:w-40" onClick={onClose}>
          {t("button.cancel")}
        </Button>
        <Button
          isPrimary
          onClick={startDownload}
          className="sm:mt-0 grow-1 ml-4 w-full sm:w-40"
        >
          {t("export_data")}
        </Button>
      </div>
    </>
  );
};

export default ExportData;
