export var DISCLAIMER_CONTENT = "I confirm that I have read and understood the Terms and Conditions. I\nfurther understand that by clicking this checkbox, I will be bound by, and\nwill bind my employer to the Terms and Conditions and my employer will be\nresponsible for the duties and obligations contained therein";
export var TERMS_AND_CONDITIONS = "terms_and_conditions";
export var PRIVACY_POLICY = "privacy_policy";
export var CONFIRMATION_CONTENT = "Are you sure you want to reject the license agreement? By clicking 'Proceed',\nyou acknowledge that you will not be able to use the software.";
export var LINKS = [{
  label: "Terms & conditions",
  id: TERMS_AND_CONDITIONS,
  href: "#".concat(TERMS_AND_CONDITIONS)
}, {
  label: "Privacy policy",
  id: PRIVACY_POLICY,
  href: "#".concat(PRIVACY_POLICY)
}];