import { Listbox, Transition } from "@headlessui/react";
import { truncate, size, trim, get } from "lodash";
import { Fragment } from "react";
import classNames from "classnames";
import AwesomeIcon from "../AwesomeIcon";
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { getTrimmedWorkspaceId } from "../../utils";

const Dropdown = (props) => {
  const {
    options,
    selectedOption,
    onChange,
    hasBorder,
    isLeftAligned,
    className = "",
    isBottomAligned,
    disabled = false,
    iconClassName,
    parentClassName = "",
  } = props;
  let selectedLabel = get(selectedOption, ["label"], "");
  selectedLabel = truncate(selectedLabel, { length: 20 });
  return (
    <Listbox
      as="div"
      className={classNames("relative w-full", {
        [parentClassName]: !!parentClassName,
      })}
      value={selectedOption}
      onChange={onChange}
    >
      {({ open }) => (
        <>
          <Listbox.Button
            className={classNames(
              "px-4 py-3 w-full rounded-md flex items-center justify-between gap-4 text-gray-600 dark:text-gray-400 outline-none",
              {
                "border border-gray-500": hasBorder,
                [className]: !!className,
              }
            )}
            aria-disabled={disabled}
          >
            <span>
              {selectedLabel}{" "}
              <span
                className={classNames("uppercase hidden ", {
                  "sm:inline-block": !!selectedOption?.id,
                })}
              >
                | {getTrimmedWorkspaceId(selectedOption?.id)}
              </span>
            </span>
            <AwesomeIcon
              name={faChevronUp}
              className={classNames("opacity-50 rotate-180", {
                "!rotate-0": open,
                hidden: disabled,
                [iconClassName]: !!iconClassName,
              })}
            />
          </Listbox.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Listbox.Options
              className={classNames(
                "absolute z-50 l-3 w-full flex flex-col max-h-[170px] overflow-y-auto scrollbar-light dark:scrollbar bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-gray-100 border border-gray-500 rounded-md text-left",
                {
                  "right-0": !isLeftAligned,
                  "bottom-[50px]": !isBottomAligned,
                }
              )}
            >
              {options &&
                options?.map((opts, index) => {
                  return (
                    <Listbox.Option key={opts?.id || index} value={opts} as={Fragment}>
                      {({ active, selected }) => (
                        <div
                          className={classNames("px-4 py-4 cursor-pointer", {
                            "border-b border-b-gray-500": size(options) - 1 !== index,
                            "bg-teal-500 text-gray-100 dark:bg-teal-800":
                              active || selected,
                          })}
                        >
                          {opts.label}
                        </div>
                      )}
                    </Listbox.Option>
                  );
                })}
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  );
};

export default Dropdown;
